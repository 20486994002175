<section>
  <div
    class="subtitle-uncollapsed list-item-subtitle"
    [ngClass]="{ 'subtitle-visible': isVisible, 'subtitle-hidden': !isVisible }"
    (click)="toggleVisible()"
  >
    <ng-content select="va-filter-section-title"></ng-content>
    <i *ngIf="!isVisible" class="material-icons arrow">keyboard_arrow_right</i>
    <i *ngIf="isVisible" class="material-icons arrow">keyboard_arrow_down</i>
  </div>
  <div class="list-item-uncollapsed" *ngIf="isVisible">
    <ng-content></ng-content>
  </div>
</section>
