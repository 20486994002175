import { FilterControl, FilterControlComponent, FilterControlOptions } from './filter-control';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

export interface RadioOption {
  label: string;
  value: string;
}

@Component({
  selector: 'va-radio-control',
  template: `
    <glxy-form-field [bottomSpacing]="'none'" [formGroup]="formGroup">
      <mat-radio-group [formControl]="control">
        <mat-radio-button *ngFor="let option of options; let i = index" [value]="option.value">
          {{ option.label | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </glxy-form-field>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./radio-filter-control.scss'],
})
export class RadioFilterControlComponent implements FilterControlComponent, OnInit {
  control: RadioFilterControl;
  formGroup: UntypedFormGroup;
  options: RadioOption[];

  ngOnInit(): void {
    if (!this.control.options) {
      throw Error('RadioFilterControlComponent requires options.');
    }
    this.options = this.control.options;
  }
}

export class RadioFilterControl extends FilterControl {
  component = RadioFilterControlComponent;

  constructor(
    public name: string,
    public label?: string,
    public options?: RadioOption[],
    formState?: any,
    opts: FilterControlOptions = {},
  ) {
    super(name, label, formState, opts);
    if (!this.options) {
      console.warn('RadioFilterControl requires options to be provided in order to function correctly.');
    }
  }
}
