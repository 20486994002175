import { Component, Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: 'va-filter-section-title, [va-filter-section-title], [vaFilterSectionTitle]',
})
export class SectionTitleDirective {
  @HostBinding('class') className = 'va-filter-section-title';
}

@Component({
  selector: 'va-filter-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
})
export class SectionComponent {
  @Input() isVisible = true;

  toggleVisible(): void {
    this.isVisible = !this.isVisible;
  }
}
