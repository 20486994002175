import moment from 'moment';

export type ValueConverter = (value: any) => any;

export const defaultConverter: ValueConverter = (value: any) => value;

export const momentConverter: ValueConverter = (value: any) => {
  if (!value) {
    return value;
  }
  return moment.isMoment(value) ? value : moment(value);
};
