<div class="filter-container">
  <div class="toolbar">
    <div class="table-controls-row">
      <div class="filter-button-and-search-bar">
        <button
          mat-stroked-button
          class="filter-button"
          *ngIf="hasFilterValues"
          (click)="toggleSidebar()"
          [ngClass]="{ selected: isSidebarVisible }"
          data-action="clicked-filter-button"
        >
          <mat-icon>filter_list</mat-icon>
          {{ 'FRONTEND.UIKIT.VA_FILTER.FILTERS' | translate }}
        </button>
        <ng-content select="[left-toolbar-item]"></ng-content>
        <ng-content select="va-filter-toolbar"></ng-content>
        <span>
          <i>
            <ng-content select="va-filter-toolbar-tooltip"></ng-content>
          </i>
        </span>
      </div>
      <div class="toolbar-actions">
        <span class="filler"></span>
        <ng-content select="va-filter-toolbar-actions"></ng-content>
      </div>
    </div>
  </div>

  <div class="flex-row">
    <div class="filter-wrapper" *ngIf="isSidebarVisible">
      <ng-content select="va-filter-title"></ng-content>
      <ng-content select="va-filter-sections"></ng-content>
    </div>
    <div class="content-container" [ngClass]="{ 'top-border': hasFilterValues }">
      <va-applied-value-list (valueCleared)="clearValue($event)"></va-applied-value-list>
      <div>
        <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
        <ng-content select="va-filter-content"></ng-content>
      </div>
    </div>
  </div>
</div>
