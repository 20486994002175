import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AppliedValueMapper } from '../../applied-value/core/applied-value-mapper';
import { DefaultSerializer, FilterControlSerializer } from '../serializer/serializer';
import { ValueConverter, defaultConverter } from '../value-converter';

export interface SelectOption {
  key: string;
  value: any;
}

export interface SelectOptionWithTranslationParams extends SelectOption {
  translationParams?: unknown;
}

export interface FilterControlComponent {
  control: FilterControl;
  formGroup: UntypedFormGroup;
}

export interface FilterControlOptions {
  hint?: string;
  hintClass?: string;
  appliedValueMapper?: AppliedValueMapper;
  validatorOrOpts?: any;
  asyncValidator?: any;
  hidden?: boolean;
  translationParams?: unknown;
}

export class FilterControl extends UntypedFormControl implements FilterControlOptions, FilterControlSerializer {
  // component is an angular component class, unfortunately cannot determine a reliable way to specify this
  component: any;
  converter: ValueConverter;
  hint?: string;
  hintClass?: string;
  appliedValueMapper?: AppliedValueMapper;
  serializer = new DefaultSerializer(this);
  hidden = false;
  translationParams?: unknown;

  constructor(public name: string, public label?: string, formState?: any, opts: FilterControlOptions = {}) {
    super(formState, opts.validatorOrOpts, opts.asyncValidator);
    this.converter = this.converter || defaultConverter;
    // copy filterOptions to FilterControl without overwriting the FormControl validators
    const { ...filterOptions } = opts;
    Object.assign(this, filterOptions);
    this.hidden = opts.hidden;
    this.translationParams = opts.translationParams;
  }

  setValue(
    value: any,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      emitModelToViewChange?: boolean;
      emitViewToModelChange?: boolean;
    },
  ): void {
    const v = this.converter(value);
    super.setValue(v, options);
  }

  serialize(): any {
    return this.serializer.serialize();
  }

  deserialize(value: any): void {
    this.serializer.deserialize(value);
  }
}
