import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'glxy-infinite-scroll-trigger',
  templateUrl: './infinite-scroll-trigger.component.html',
  styleUrls: ['./infinite-scroll-trigger.component.scss'],
})
export class InfiniteScrollTriggerComponent implements OnInit, OnDestroy, AfterViewInit {
  @HostBinding('class') class = 'glxy-infinite-scroll-trigger';

  /** In Pixels (px). How far above the element to trigger `isVisible` emitter */
  @Input() visiblilityMargin = 120;

  /** In Pixels (px). The minimum height of the is-visible component. Useful if you want more scroll room for infinite scroll */
  @Input() minHeight = 0;

  /** Emits when component (with visiblilityMargin) enters the viewport */
  @Output() isVisible: EventEmitter<null> = new EventEmitter();

  /** Emits when component (with visiblilityMargin) leaves the viewport */
  @Output() isHidden: EventEmitter<null> = new EventEmitter();

  @HostBinding('style.margin-top') get marginTopStyle(): string {
    return `-${this.visiblilityMargin}px`;
  }

  @HostBinding('style.padding-top') get paddingTopStyle(): string {
    return `${this.visiblilityMargin}px`;
  }

  private observer?: IntersectionObserver;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting === true) {
          this.isVisible.emit();
        } else {
          this.isHidden.emit();
        }
      },
      {
        threshold: 0,
      },
    );
  }

  ngAfterViewInit(): void {
    this.observer?.observe(this.el.nativeElement as HTMLElement);
  }

  ngOnDestroy(): void {
    this.observer?.disconnect();
  }
}
