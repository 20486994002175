<va-filter2
  [name]="filterGroup.name"
  [formGroup]="filterGroup"
  [isLoading]="isLoading"
  [hideFromSidebar]="filterGroup.hideFromSidebar"
  [isSidebarVisible]="defaultSidebarState === 0"
>
  <va-filter-title *ngIf="title">
    <va-filter-title-component [title]="title"></va-filter-title-component>
  </va-filter-title>
  <va-filter-toolbar *ngIf="filterGroup && filterGroup.toolbarControl">
    <va-filter-field
      [control]="filterGroup.toolbarControl"
      [formGroup]="filterGroup"
      [isToolbar]="true"
    ></va-filter-field>
    <va-filter-toolbar-tooltip *ngIf="filterGroup.toolbarControl.hint">
      {{ filterGroup.toolbarControl.hint | translate }}
    </va-filter-toolbar-tooltip>
    <va-filter-toolbar-tooltip *ngIf="toolTip">
      {{ toolTip | translate }}
    </va-filter-toolbar-tooltip>
    <ng-content select="va-filter-toolbar-tooltip"></ng-content>
  </va-filter-toolbar>
  <va-filter-toolbar *ngIf="filterGroup && !filterGroup.toolbarControl">
    <va-filter-toolbar-tooltip *ngIf="toolTip">
      {{ toolTip | translate }}
    </va-filter-toolbar-tooltip>
    <ng-content select="va-filter-toolbar-tooltip"></ng-content>
  </va-filter-toolbar>
  <va-filter-toolbar-actions>
    <ng-content select="va-filter-toolbar-actions"></ng-content>
  </va-filter-toolbar-actions>

  <va-filter-sections
    *ngIf="filterGroup && filterGroup.sections?.length > 0"
    [formGroup]="filterGroup"
  >
    <va-filter-section
      *ngFor="let section of filterGroup.sidebarSections"
      [isVisible]="section.isVisible"
    >
      <va-filter-section-title *ngIf="section.name">
        {{ section.name | translate }}
      </va-filter-section-title>
      <ng-container *ngFor="let control of section.controls; let last = last">
        <va-filter-field
          [ngClass]="{ hidden: control.hidden }"
          [control]="control"
          [formGroup]="filterGroup"
          [isLast]="last"
        ></va-filter-field>
      </ng-container>
    </va-filter-section>
  </va-filter-sections>

  <va-filter-content>
    <ng-content select="va-filter-content"></ng-content>
  </va-filter-content>
</va-filter2>
