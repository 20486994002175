import { Component, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FilterControl, FilterControlComponent } from './filter-control';

@Component({
  template: `
    <glxy-form-field [formGroup]="formGroup" [bottomSpacing]="'none'" [size]="'small'">
      <mat-checkbox
        color="primary"
        [formControl]="control"
        [matTooltip]="control.hint"
        [matTooltipClass]="control.hintClass"
      >
        {{ control.label | translate }}
      </mat-checkbox>
    </glxy-form-field>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxFilterControlComponent implements FilterControlComponent {
  control: CheckboxFilterControl;
  formGroup: UntypedFormGroup;
}

export class CheckboxFilterControl extends FilterControl {
  component = CheckboxFilterControlComponent;
}
