import { UntypedFormGroup, ValidatorFn, AsyncValidatorFn, AbstractControlOptions } from '@angular/forms';
import { FilterControl } from './filter-control/filter-control';

class FilterSection {
  constructor(public name: string, public controls: FilterControl[], public isVisible = true) {}
}

export class FilterToolbarSection extends FilterSection {
  constructor(public control: FilterControl) {
    super('Toolbar', [control]);
  }
}

export class FilterGroup extends UntypedFormGroup {
  private _sections: FilterSection[] = [];

  constructor(
    public name: string,
    controls: FilterControl[] = [],
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
  ) {
    super(controlsToMap(controls), validatorOrOpts, asyncValidator);
  }

  get toolbarControl(): FilterControl {
    const toolbarSection = this._sections.find((s) => s instanceof FilterToolbarSection);
    if (toolbarSection) {
      return (toolbarSection as FilterToolbarSection).control;
    }
    return null;
  }

  get sections(): FilterSection[] {
    return this._sections;
  }

  get sidebarSections(): FilterSection[] {
    return this._sections.filter((s) => !(s instanceof FilterToolbarSection));
  }

  // names of controls to hide from sidebar (and applied value list)
  get hideFromSidebar(): string[] {
    return this.toolbarControl ? [this.toolbarControl.name] : [];
  }

  public initValue(value: { [key: string]: any }): void {
    if (!value) {
      return;
    }

    Object.keys(value).forEach((k) => {
      const c = this.get(k) as FilterControl;
      if (!c) {
        return;
      }
      c.deserialize(value[k]);
    });
  }

  public addToolbarSection(control: FilterControl): FilterGroup {
    const section = new FilterToolbarSection(control);
    this.pushSection(section);
    return this;
  }

  public addSection(name: string, controls: FilterControl[], isVisible?: boolean): FilterGroup {
    const section = new FilterSection(name, controls, isVisible);
    this.pushSection(section);
    return this;
  }

  public prependSection(name: string, controls: FilterControl[], isVisible?: boolean): FilterGroup {
    const section = new FilterSection(name, controls, isVisible);
    this.unshiftSection(section);
    return this;
  }

  public removeSection(name: string): FilterGroup {
    const section = this._sections.find((s) => s.name === name);
    if (!section) {
      return;
    }
    const controlMap = sectionsToControls([section]);
    Object.keys(controlMap).forEach((n) => {
      this.removeControl(n);
    });
    this._sections.splice(this._sections.indexOf(section), 1);
    return this;
  }

  private pushSection(section: FilterSection): void {
    const controlMap = sectionsToControls([section]);
    Object.keys(controlMap).forEach((name) => {
      const control = controlMap[name];
      this.addControl(name, control);
    });
    this._sections.push(section);
  }

  private unshiftSection(section: FilterSection): void {
    const controlMap = sectionsToControls([section]);
    Object.keys(controlMap).forEach((name) => {
      const control = controlMap[name];
      this.addControl(name, control);
    });
    this._sections.unshift(section);
  }
}

function sectionsToControls(sections: FilterSection[]): { [key: string]: FilterControl } {
  let controls: { [key: string]: FilterControl } = {};

  sections.forEach((s) => {
    controls = {
      ...controls,
      ...controlsToMap(s.controls),
    };
  });

  return controls;
}

function controlsToMap(controls: FilterControl[]): { [key: string]: FilterControl } {
  return controls.reduce((prev, c) => {
    prev[c.name] = c;
    return prev;
  }, {});
}
