import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, shareReplay, switchMap, startWith } from 'rxjs/operators';
import moment from 'moment';
import { AppliedValue } from './applied-value';
import { AppliedValueMapper, booleanMapper, momentMapper, dateMapper, defaultMapper } from './applied-value-mapper';
import { FilterService } from '../../core/filter.service';
import { FilterControl } from '../../core/filter-control/filter-control';
import { FilterGroup, FilterToolbarSection } from '../../core/filter';

@Injectable()
export class AppliedValueService {
  mappers = new Map<string, AppliedValueMapper>();
  appliedValues$: Observable<AppliedValue[]>;

  constructor(private filterService: FilterService) {
    this.appliedValues$ = this.filterService.filterGroup$.pipe(
      switchMap((filterGroup) => {
        if (!filterGroup) {
          return of(null);
        }
        // whenever a value change occurs emit the filter group
        return filterGroup.valueChanges.pipe(
          startWith(filterGroup.value),
          map(() => filterGroup),
        );
      }),
      map((filterGroup) => {
        if (!filterGroup) {
          return [];
        }

        return this.mapFilterGroup(filterGroup);
      }),
      shareReplay(1),
    );
  }

  mapFilterGroup(filterGroup: FilterGroup): AppliedValue[] {
    const toolbarSections = filterGroup.sections.filter((section) => section instanceof FilterToolbarSection);
    toolbarSections.forEach((section) => {
      section.controls.forEach((filter) => {
        if (this.filterService.hideControlsFromSidebar.indexOf(filter.name) === -1) {
          this.filterService.hideControlsFromSidebar.push(filter.name);
        }
      });
    });

    return Object.keys(filterGroup.controls)
      .filter((name) => this.filterService.hideControlsFromSidebar.indexOf(name) === -1 && !!filterGroup.value[name])
      .reduce((prev, name) => {
        const c = filterGroup.controls[name] as FilterControl;
        const values = Array.isArray(c.value) ? c.value : [c.value];

        const appliedValues = values.map((v) => {
          return {
            name,
            value: v,
            ...this.mapValue(this.filterService.filterName, name, v, c.appliedValueMapper),
          };
        });
        return [...prev, ...appliedValues];
      }, []);
  }

  // map filter key/value to applied field
  mapValue(filterName: string, key: string, value: any, mapper?: AppliedValueMapper): AppliedValue {
    mapper = mapper || this.getMapper(filterName, key, value);
    return mapper(key, value);
  }

  private getMapper(filterName: string, key: string, value: any): AppliedValueMapper {
    const keyName = this.getKeyName(filterName, key);
    if (this.mappers.has(keyName)) {
      return this.mappers.get(keyName);
    }

    if (typeof value === 'boolean') {
      return booleanMapper;
    }

    if (moment.isMoment(value)) {
      return momentMapper;
    }

    if (value instanceof Date) {
      return dateMapper;
    }

    return defaultMapper;
  }

  private getKeyName(filterName: string, key: string): string {
    return `${filterName}:${key}`;
  }
}
