import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FilterControl, FilterControlComponent } from './filter-control';

@Component({
  template: `
    <span [ngClass]="{ hidden: control.hidden }">
      <va-search-box
        #search
        [placeholder]="control.label || '' | translate"
        (update)="searchUpdated($event)"
        [initial]="control.value"
      ></va-search-box>
    </span>
  `,
  styleUrls: ['./search-filter-control.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFilterControlComponent implements FilterControlComponent {
  control: SearchFilterControl;
  formGroup: UntypedFormGroup;

  searchUpdated(searchTerm: string): void {
    this.control.setValue(searchTerm);
  }
}

export class SearchFilterControl extends FilterControl {
  component = SearchFilterControlComponent;
}
