import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, BehaviorSubject, combineLatest, of } from 'rxjs';
import { map, tap, takeUntil } from 'rxjs/operators';
import { FilterControlSerializer } from '../serializer/serializer';
import {
  FilterControlComponent,
  FilterControl,
  FilterControlOptions,
  SelectOptionWithTranslationParams,
} from './filter-control';

class SelectSerializer implements FilterControlSerializer {
  serialized = new BehaviorSubject<string>(null);

  constructor(public readonly control: SelectFilterControl) {
    if (!this.control.options$) {
      console.warn('SelectSerializer requires options$ to be provided in order to function correctly.');
      return;
    }

    combineLatest(this.control.valueChanges, this.control.options$).subscribe(([value, o]) => {
      if (!value) {
        this.serialized.next(value);
      } else {
        o.forEach((ov, k) => {
          // find the option value that matches the value set on the control and store its key
          if (JSON.stringify(ov) === JSON.stringify(value)) {
            this.serialized.next(k);
          }
        });
      }
    });
  }

  serialize(): any {
    return this.serialized.value;
  }

  deserialize(key: any): void {
    if (!key) {
      return;
    }

    this.control.options$
      .pipe(
        tap((o) => {
          const v = o.get(key);
          if (v) {
            this.control.setValue(v);
          }
        }),
        // once the value is set (here or elsewhere) complete this observable
        takeUntil(this.control.valueChanges),
      )
      .subscribe();
  }
}

@Component({
  template: `
    <glxy-form-field [bottomSpacing]="'none'" [formGroup]="formGroup">
      <glxy-label *ngIf="control.label">{{ control.label | translate }}</glxy-label>
      <mat-select [formControl]="control" [compareWith]="compareObjects">
        <mat-option *ngFor="let o of optionsWithTranslationParams$ | async" [value]="o.value">
          {{ o.key | translate : o.translationParams }}
        </mat-option>
      </mat-select>
    </glxy-form-field>
  `,
  styles: [],
})
export class SelectFilterControlComponent implements FilterControlComponent, OnInit {
  control: SelectFilterControl;
  formGroup: UntypedFormGroup;
  optionsWithTranslationParams$?: Observable<SelectOptionWithTranslationParams[]>;

  ngOnInit(): void {
    if (!this.control.options$) {
      console.warn('SelectFilterControlComponent requires options$.');
      return;
    }

    this.optionsWithTranslationParams$ = combineLatest([this.control.options$, this.control.translationParams$]).pipe(
      map(([o, p]) => {
        const options = [];
        // cannot map over a Map
        o.forEach((value, key) => options.push({ key, value, translationParams: p.get(key) }));
        return options;
      }),
    );
  }

  compareObjects(x: any, y: any): boolean {
    return !!x && !!y && JSON.stringify(x) === JSON.stringify(y);
  }
}

export class SelectFilterControl extends FilterControl {
  component = SelectFilterControlComponent;
  serializer = new SelectSerializer(this);

  constructor(
    public name: string,
    public label?: string,
    public options$?: Observable<Map<string, any>>,
    formState?: any,
    opts: FilterControlOptions = {},
    public translationParams$: Observable<Map<string, unknown>> = of(new Map()),
  ) {
    super(name, label, formState, opts);
    if (!this.options$) {
      console.warn('MultiSelectFilterControl requires options$ to be provided in order to function correctly.');
    }
  }
}
