import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SearchFunc } from '@vendasta/uikit';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { map, tap, takeUntil } from 'rxjs/operators';
import { FilterControlOptions, FilterControlComponent, FilterControl } from './filter-control';
import { FilterControlSerializer } from '../serializer/serializer';

export interface MultiSelectFilterControlOptions extends FilterControlOptions {
  displayProperty?: string;
  searchFunc?: SearchFunc;
}

class MultiSelectSerializer implements FilterControlSerializer {
  serialized = new BehaviorSubject<string[]>(null);

  constructor(public readonly control: MultiSelectFilterControl) {
    if (!this.control.options$) {
      console.warn('MultiSelectSerializer requires options$ to be provided in order to function correctly.');
      return;
    }

    combineLatest(this.control.valueChanges, this.control.options$).subscribe(([value, o]) => {
      const keys = [];
      o.forEach((v, k) => {
        if (value && value.indexOf(v) !== -1) {
          keys.push(k);
        }
      });
      this.serialized.next(keys);
    });
  }

  serialize(): any {
    return this.serialized.value;
  }

  deserialize(keys: any[]): void {
    if (!keys) {
      return;
    }

    this.control.options$
      .pipe(
        tap((o) => {
          const v = keys.map((k) => o.get(k)).filter((k) => !!k);
          if (v.length > 0) {
            this.control.setValue(v);
          }
        }),
        takeUntil(this.control.valueChanges),
      )
      .subscribe();
  }
}

@Component({
  // There is an issue with the UI not updating properly when programmatically setting the value of a multi select.
  // Passing `(control.valueChanges|async) || [] resolves this but causes multiple multiselects in the same filter to set each others value.
  template: `
    <ng-template *ngIf="(control.valueChanges | async) || []"></ng-template>
    <va-multi-select
      *ngIf="options$ | async as o"
      (selection)="valueSelected($event)"
      [options]="o"
      [selected]="control.value || []"
      [displayProperty]="control.displayProperty"
      [placeholderText]="control.label | translate"
      [searchFunc]="control.searchFunc"
    ></va-multi-select>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectFilterControlComponent implements FilterControlComponent, OnInit {
  control: MultiSelectFilterControl;
  formGroup: UntypedFormGroup;
  options$: Observable<any[]>;

  ngOnInit(): void {
    if (!this.control.options$) {
      console.warn('MultiSelectFilterControlComponent requires options$.');
      return;
    }

    this.options$ = this.control.options$.pipe(map((o) => Array.from(o.values())));
  }

  valueSelected(value: any): void {
    this.control.setValue(value);
  }
}

export class MultiSelectFilterControl extends FilterControl {
  component = MultiSelectFilterControlComponent;
  displayProperty?: string;
  serializer = new MultiSelectSerializer(this);
  searchFunc: SearchFunc;

  constructor(
    public name: string,
    public label?: string,
    public options$?: Observable<Map<string, any>>,
    formState?: any,
    opts: MultiSelectFilterControlOptions = {},
  ) {
    super(name, label, formState, opts);
    if (!this.options$) {
      console.warn('MultiSelectFilterControl requires options$ to be provided in order to function correctly.');
    }
    this.displayProperty = opts.displayProperty;
    if (opts?.searchFunc) {
      this.searchFunc = opts.searchFunc;
    }
    this.setValue([]);
  }
}
