import { Injectable, OnDestroy } from '@angular/core';
import { Subscription, BehaviorSubject, Subject } from 'rxjs';
import { URLParamService, LocalStorageService } from '@vendasta/uikit';
import { ActivatedRoute } from '@angular/router';
import { serializeValue, deserializeValue } from './util';
import { FilterControl } from './filter-control/filter-control';
import { FilterGroup } from './filter';

const EmptyObject = '{}';
const DefaultFilterName = '__last_applied_filter__';
const DefaultExpiry = 30 * 60 * 1000; // 30 min expiry time for last applied fields

interface SavedFilter {
  name: string;
  serializedValue: string;
  expiry: number;
}

@Injectable()
export class FilterService implements OnDestroy {
  private subscription = new Subscription();

  private filterGroup$$ = new BehaviorSubject<FilterGroup>(null);
  public readonly filterGroup$ = this.filterGroup$$.asObservable();

  private initialValueLoaded$$ = new Subject<any>();
  public readonly initialValueLoaded$ = this.initialValueLoaded$$.asObservable();

  public filterName: string;
  public hideControlsFromSidebar: string[];

  constructor(
    private route: ActivatedRoute,
    private urlParamService: URLParamService,
    private localStorageService: LocalStorageService,
  ) {}

  public registerFilter(name: string, filterGroup?: FilterGroup, hideControlsFromSidebar: string[] = []): void {
    // Clean up the old filter
    this.cleanup(this.filterName);

    this.filterName = name;
    this.hideControlsFromSidebar = hideControlsFromSidebar;
    this.initFilterGroupValue(filterGroup);
    this.trackFilterGroup(filterGroup);
  }

  public clearValue(name: string, value: any): void {
    const fg = this.filterGroup$$.value;
    const c = fg.get(name);
    if (Array.isArray(c.value)) {
      c.setValue(c.value.filter((v) => v !== value));
    } else {
      c.reset();
    }
  }

  private trackFilterGroup(filterGroup: FilterGroup): void {
    if (!this.filterName) {
      console.warn('You must registerFilter with a filterName before trackFilterGroup can be called.');
      return;
    }

    if (filterGroup) {
      const s = filterGroup.valueChanges.subscribe(() => {
        this.saveFilterGroup(this.filterName, filterGroup);
      });
      this.addSubscription(s);
    }
    this.filterGroup$$.next(filterGroup);
  }

  private initFilterGroupValue(filterGroup: FilterGroup): void {
    const v = this.loadFilterGroupValue(this.filterName);
    if (v && this.initialValueLoaded$$.observers.length === 0) {
      // Auto patch filter group unless someone is subscribing to initialValueLoaded$ and will handle manually
      filterGroup.initValue(v);
    }
    this.initialValueLoaded$$.next(v);
  }

  /**
   * URL value takes priority over anything in Local Storage
   */
  private loadFilterGroupValue(filterName: string): any {
    if (!filterName) {
      console.warn('You must registerFilter with a filterName before loadFilterGroupValue can be called.');
      return null;
    }

    const urlValue = this.getUrlValue(filterName);
    if (urlValue) {
      return urlValue;
    }
    return this.getLocalStorageValue(filterName);
  }

  private saveFilterGroup(filterName: string, filterGroup: FilterGroup): void {
    const serializedValues = Object.keys(filterGroup.controls).reduce((value, k) => {
      const c = filterGroup.get(k) as FilterControl;
      value[k] = c.serialize();
      return value;
    }, {});

    const sv = serializeValue(serializedValues);
    this.setUrlValue(filterName, sv);
    this.setLocalStorageValue(filterName, sv);
  }

  private getUrlValue(filterName: string): any {
    const params = this.route.snapshot.queryParams;
    if (!params[filterName]) {
      return null;
    }
    return deserializeValue(decodeURIComponent(params[filterName]));
  }

  private setUrlValue(filterName: string, serializedValue: string): void {
    if (serializedValue !== EmptyObject) {
      const p = {};
      p[filterName] = serializedValue;
      this.urlParamService.applyParams(p);
    } else {
      this.urlParamService.removeParams([filterName]);
    }
  }

  private getLocalStorageValue(filterName: string): any {
    const rawSavedFilter = this.localStorageService.get(filterName);
    if (!rawSavedFilter) {
      return null;
    }

    const savedFilter = deserializeValue(rawSavedFilter) as SavedFilter;
    const savedValue = deserializeValue(savedFilter.serializedValue);
    if (!savedFilter.expiry || savedFilter.expiry < Date.now()) {
      // filter is expired, remove it from localstorage
      this.removeLocalStorageValue(filterName);
      return null;
    }

    return savedValue;
  }

  private setLocalStorageValue(filterName: string, serializedValue: string): void {
    const savedFilter = {
      name: DefaultFilterName,
      serializedValue: serializedValue,
      expiry: Date.now() + DefaultExpiry,
    };
    this.localStorageService.set(filterName, serializeValue(savedFilter));
  }

  private removeLocalStorageValue(filterName: string): void {
    this.localStorageService.remove(filterName);
  }

  private cleanup(filterName: string): void {
    this.urlParamService.removeParams([filterName]);
    this.subscription.unsubscribe();
    this.filterGroup$$.next(null);
  }

  private addSubscription(s: Subscription): void {
    if (this.subscription.closed) {
      this.subscription = new Subscription();
    }
    this.subscription.add(s);
  }

  ngOnDestroy(): void {
    this.cleanup(this.filterName);
  }
}
