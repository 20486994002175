import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Directive,
  ViewEncapsulation,
  HostBinding,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { FilterService } from '../core/filter.service';
import { FilterGroup } from '../core/filter';
import { AppliedValue } from '../applied-value/core/applied-value';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Directive({
  selector: 'va-filter-toolbar, [va-filter-toolbar], [vaFilterToolbar]',
})
export class FilterToolbarDirective {
  @HostBinding('class') className = 'va-filter-toolbar';
}

@Directive({
  selector: 'va-filter-toolbar-tooltip, [va-filter-toolbar-tooltip], [vaFilterToolbarTooltip]',
})
export class FilterToolbarTooltipDirective {
  @HostBinding('class') className = 'va-filter-toolbar-tooltip';
}

@Directive({
  selector: 'va-filter-toolbar-actions, [va-filter-toolbar-actions], [vaFilterToolbarActions]',
})
export class FilterToolbarActionsDirective {
  @HostBinding('class') className = 'va-filter-toolbar-actions';
}

@Directive({
  selector: 'va-filter-sections, [va-filter-sections], [vaFilterSections]',
})
export class FilterSectionsDirective {
  @HostBinding('class') className = 'va-filter-sections';
}

@Directive({
  selector: 'va-filter-content, [va-filter-content], [vaFilterContent]',
})
export class FilterContentDirective {
  @HostBinding('class') className = 'va-filter-content';
}

@Directive({
  selector: 'va-filter-title, [va-filter-title], [vaFilterTitle]',
})
export class FilterTitleDirective {
  @HostBinding('class') className = 'va-filter-title';
}

@Component({
  selector: 'va-filter-title-component',
  template: `
    <div class="title">{{ title }}</div>
  `,
  styles: [
    `
      .title {
        align-items: center;
        display: flex;
        padding: 8px 16px;
        border-top: 1px solid #e0e0e0;
      }

      h2 {
        flex-grow: 1;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterTitleComponent {
  @Input() title: string;
}

@Component({
  selector: 'va-filter2',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterComponent implements OnInit {
  @Input() hideFromSidebar = [];
  @Input() name: string;
  @Input() formGroup: FilterGroup; // this input needs to be named formGroup to work with reactive form directives
  @Input() isLoading: boolean;
  @Input() isSidebarVisible = false;

  constructor(
    private filterService: FilterService,
    private breakPointObserver: BreakpointObserver,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.filterService.registerFilter(this.name, this.formGroup, this.hideFromSidebar);
    this.breakPointObserver.observe(Breakpoints.XSmall).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isSidebarVisible = false;
        this.cdr.detectChanges();
      }
    });
  }

  get hasFilterValues(): boolean {
    if (!this.formGroup) {
      return false;
    }
    const filterValues = Object.keys(this.formGroup.controls)
      .filter((k) => this.hideFromSidebar.indexOf(k) < 0)
      .reduce((values, k) => {
        values[k] = this.formGroup.value[k];
        return values;
      }, {});
    return filterValues ? Object.keys(filterValues).length > 0 : false;
  }

  toggleSidebar(): void {
    this.isSidebarVisible = !this.isSidebarVisible;
  }

  clearValue(appliedValue: AppliedValue): void {
    this.filterService.clearValue(appliedValue.name, appliedValue.value);
  }
}
