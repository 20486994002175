import { Component, Input, ViewChild, ComponentFactoryResolver, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FilterControl, FilterControlComponent } from '../core/filter-control/filter-control';
import { ControlHostDirective } from '../shared/control-host.directive';

@Component({
  selector: 'va-filter-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldComponent implements OnInit {
  @Input() control: FilterControl;
  @Input() formGroup: UntypedFormGroup;
  @Input() isToolbar: boolean;
  @Input() isLast: boolean;
  @ViewChild(ControlHostDirective, { static: true }) controlHost: ControlHostDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnInit(): void {
    this.createComponent();
  }

  createComponent(): void {
    if (!(this.control && this.formGroup)) {
      return;
    }

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory<FilterControlComponent>(
      this.control.component,
    );

    const viewContainerRef = this.controlHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    componentRef.instance.formGroup = this.formGroup;
    componentRef.instance.control = this.control;
  }
}
