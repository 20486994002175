import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { FilterGroup } from '../core/filter';

export enum SidebarState {
  OPEN = 0,
  CLOSED = 1,
}

@Component({
  selector: 'va-filter2-model',
  templateUrl: './filter-model.component.html',
  styleUrls: ['./filter-model.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterModelComponent implements OnInit {
  @Input() filterGroup: FilterGroup;
  @Input() isLoading: boolean;
  @Input() defaultSidebarState = SidebarState.CLOSED;
  @Input() toolTip = '';
  @Input() title = '';

  ngOnInit(): void {
    if (!this.filterGroup) {
      console.error('va-filter2-model cannot be used without providing a filterGroup.');
    }
    // Ensures the filterGroup.valueChanges has an initial state.
    // Otherwise observable will block subscribe operations until a filter is set.
    this.filterGroup.updateValueAndValidity();
  }
}
