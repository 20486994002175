import { Component, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { AppliedValue } from '../core/applied-value';
import { AppliedValueService } from '../core/applied-value.service';

@Component({
  selector: 'va-applied-value-list',
  templateUrl: './applied-value-list.component.html',
  styleUrls: ['./applied-value-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppliedValueListComponent {
  @Output() valueCleared = new EventEmitter<AppliedValue>();

  appliedFields$: Observable<AppliedValue[]>;

  constructor(private appliedValueService: AppliedValueService) {
    this.appliedFields$ = this.appliedValueService.appliedValues$;
  }

  clearValue(appliedValue: AppliedValue): void {
    this.valueCleared.emit(appliedValue);
  }
}
