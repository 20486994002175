import { FilterControl } from '../filter-control/filter-control';

export interface FilterControlSerializer {
  serialize(): any;
  deserialize(value: any): void;
}

export class DefaultSerializer implements FilterControlSerializer {
  constructor(public readonly control: FilterControl) {}

  serialize(): any {
    return this.control.value;
  }

  deserialize(value: any): void {
    this.control.setValue(value);
  }
}
