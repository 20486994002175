import moment from 'moment';
import { AppliedValue } from './applied-value';

export type AppliedValueMapper = (name: string, value: any) => AppliedValue;

export const defaultMapper: AppliedValueMapper = (name: string, value: any) => {
  const keyName = toSentenceCase(name);
  return { name, label: `${keyName}: ${value}` };
};

export const booleanMapper: AppliedValueMapper = (name: string) => {
  return { name, label: name };
};

export const momentMapper: AppliedValueMapper = (name: string, value: any) => {
  const keyName = toSentenceCase(name);
  const label = `${keyName}: ${moment(value).format('MMMM Do, YYYY')}`;

  return { name, label };
};

export const dateMapper: AppliedValueMapper = (name: string, value: any) => {
  const keyName = toSentenceCase(name);
  const label = `${keyName}: ${value.toDateString()}`;

  return { name, label };
};

// Converts "thisIsMyMessage" to "This Is My Message"
function toSentenceCase(name: string): string {
  if (!name) {
    return '';
  }
  const transformed = name.replace(/([A-Z])/g, ' $1');
  return transformed.charAt(0).toUpperCase() + transformed.slice(1);
}
