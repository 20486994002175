<ng-container *ngIf="appliedFields$ | async as appliedFields">
  <mat-chip-listbox *ngIf="appliedFields?.length > 0" class="applied-filters-bar">
    <ng-container *ngFor="let field of appliedFields">
      <mat-chip-option>
        {{ field.label | translate }}
        <mat-icon matChipRemove (click)="clearValue(field)">cancel</mat-icon>
      </mat-chip-option>
    </ng-container>
  </mat-chip-listbox>
</ng-container>
