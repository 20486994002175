import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { VaSearchBoxModule, UIKitModule, VaMultiSelectModule } from '@vendasta/uikit';
import {
  FilterComponent,
  FilterToolbarDirective,
  FilterToolbarTooltipDirective,
  FilterSectionsDirective,
  FilterTitleComponent,
  FilterContentDirective,
  FilterToolbarActionsDirective,
  FilterTitleDirective,
} from './filter/filter.component';
import { SectionComponent, SectionTitleDirective } from './section/section.component';
import { FieldComponent } from './field/field.component';
import { AppliedValueListComponent } from './applied-value/applied-value-list/applied-value-list.component';
import { AppliedValueService } from './applied-value/core/applied-value.service';
import { FilterService } from './core/filter.service';
import { DateFilterControlComponent } from './core/filter-control/date-filter-control';
import { SearchFilterControlComponent } from './core/filter-control/search-filter-control';
import { SelectFilterControlComponent } from './core/filter-control/select-filter-control';
import { MultiSelectFilterControlComponent } from './core/filter-control/multi-select-filter-control';
import { CheckboxFilterControlComponent } from './core/filter-control/checkbox-filter-control';
import { RadioFilterControlComponent } from './core/filter-control/radio-filter-control';
import { SearchSelectFilterControlComponent } from './core/filter-control/search-select-filter-control';

import { ControlHostDirective } from './shared/control-host.directive';
import { FilterModelComponent } from './filter-model/filter-model.component';
import { NumberInputFilterControlComponent } from './core/filter-control/number-input-filter-control';
import { MatChipsModule } from '@angular/material/chips';
import { ChipsInputFilterControlComponent } from './core/filter-control/chips-input-filter-control';
import { TranslateModule } from '@ngx-translate/core';
import { TextInputFilterControlComponent } from './core/filter-control/text-input-filter-control';
import { GalaxyWrapModule } from '@vendasta/galaxy/galaxy-wrap';
import { MultiSearchSelectFilterControlComponent } from './core/filter-control/multi-search-select-filter-control';
import { GalaxyInfiniteScrollTriggerModule } from '@vendasta/galaxy/infinite-scroll-trigger';
import { GalaxyFormFieldModule } from '@vendasta/galaxy/form-field';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    // Material
    MatIconModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatTooltipModule,
    MatButtonModule,
    MatChipsModule,
    VaSearchBoxModule,
    UIKitModule,
    MatAutocompleteModule,
    VaMultiSelectModule,
    TranslateModule,
    GalaxyWrapModule,
    GalaxyInfiniteScrollTriggerModule,
    GalaxyFormFieldModule,
  ],
  exports: [
    FilterComponent,
    FilterToolbarDirective,
    FilterToolbarTooltipDirective,
    FilterToolbarActionsDirective,
    FilterSectionsDirective,
    FilterTitleComponent,
    FilterContentDirective,
    SectionComponent,
    SectionTitleDirective,
    FieldComponent,
    FilterModelComponent,
  ],
  declarations: [
    FilterComponent,
    FilterToolbarDirective,
    FilterToolbarTooltipDirective,
    FilterToolbarActionsDirective,
    FilterSectionsDirective,
    FilterTitleComponent,
    FilterContentDirective,
    FilterTitleDirective,
    SectionComponent,
    SectionTitleDirective,
    FieldComponent,
    AppliedValueListComponent,
    ControlHostDirective,
    // filter controls
    DateFilterControlComponent,
    SearchFilterControlComponent,
    SelectFilterControlComponent,
    MultiSelectFilterControlComponent,
    CheckboxFilterControlComponent,
    RadioFilterControlComponent,
    SearchSelectFilterControlComponent,
    NumberInputFilterControlComponent,
    ChipsInputFilterControlComponent,
    TextInputFilterControlComponent,
    FilterModelComponent,
    MultiSearchSelectFilterControlComponent,
  ],
  providers: [AppliedValueService, FilterService],
})
export class FilterModule {}
